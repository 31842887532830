import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging/sw";

// Initialize the Firebase app in the service worker by passing in
// your app's Firebase config object.
// https://firebase.google.com/docs/web/setup#config-object
const firebaseApp = initializeApp({
  apiKey: "AIzaSyBr8jnyOuaoDnskAuCoEpxoq4-8MSbACDg",
  authDomain: "tingtong-d4422.firebaseapp.com",
  projectId: "tingtong-d4422",
  storageBucket: "tingtong-d4422.appspot.com",
  messagingSenderId: "238535600907",
  appId: "1:238535600907:web:603bd465e734d14052d00e",
  measurementId: "G-XQFQH90ZFY",
});

// Retrieve an instance of Firebase Messaging so that it can handle background
// messages.

const messaging = getMessaging(firebaseApp);
export default messaging;
